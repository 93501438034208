import React, { useState } from 'react';

const JourneyRunReport = (reportFinalData) => {
    let reportData = reportFinalData.report;
    console.log('reportData:', reportData);
    const [copySuccess, setCopySuccess] = useState('Copy Sharable URL of the Report');
    const [showVideo, setShowVideo] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_WEBSITE_DOMAIN}/report?journey_run_id=${reportData.journeyRunId}org_id=${reportData.organisationId}`).then(() => {
            setCopySuccess('Copied to clipboard!');
            setTimeout(() => {
                setCopySuccess('Copy Sharable URL of the Report');
            }, 2000);
        });
    };

    const toggleVideo = () => {
        setShowVideo(!showVideo);
    };

    return (
        <div className="min-h-screen bg-black text-[#b29f8a] font-sans">
            <div className="text-center p-4">
                <a href="https://autoflow.tools" target="_blank" rel="noopener noreferrer">
                    <img
                        src="https://autoflow-static-data.s3.ap-south-1.amazonaws.com/autoflow.png"
                        alt="Autoflow Logo"
                        className="mx-auto max-w-md max-h-40"
                    />
                </a>
                <h1 className="text-4xl font-bold">User Journey Run Report</h1>
                <div className="divider">{reportData.journeyName}</div>
                {/* <input type="text" className="hidden" id="valueToCopy" value={reportData.reportUrl} readOnly />
        <button className="btn btn-outline btn-warning" onClick={handleCopy}>
          {copySuccess}
        </button> */}
            </div>

            <div className="px-12 flex flex-col gap-2">
                <p className="text-start text-[#B29F8A]">Run ID: {reportData.journeyRunId}</p>

                <div className="stats stats-vertical lg:stats-horizontal shadow mx-auto bg-[#2b2b2b] w-full px-12">
                    <div className="stat">
                        <div className="stat-figure text-primary">
                            <svg viewBox="0 0 32 32" className="inline-block w-8 h-8">
                                <path
                                    fill="#60A5FA"
                                    d="M265,675 C264.448,675 264,675.448 264,676 C264,676.553 264.448,677 265,677 C265.552,677 266,676.553 266,676 C266,675.448 265.552,675 265,675 L265,675 Z..."
                                />
                            </svg>
                        </div>
                        <div className="stat-title text-secondary">Browser</div>
                        <div className="stat-value text-[#60A5FA]">{reportData.browserType}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-figure text-secondary">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current">
                                <path d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                            </svg>
                        </div>
                        <div className="stat-title text-[#60A5FA]">Device</div>
                        <div className="stat-value text-secondary">{reportData.device}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-title text-[#60A5FA]">Duration</div>
                        <div className="stat-value text-secondary">{reportData.journeyDurationMiliseconds / 1000} seconds</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value text-[#60A5FA]">{reportData.journeyProgress}%</div>
                        <div className="stat-title text-secondary">Tests done</div>
                    </div>
                </div>

                <div className="stats stats-vertical lg:stats-horizontal shadow mx-auto bg-[#2b2b2b] w-full px-12">
                    <div className="stat flex justify-center flex-col items-center">
                        <button className="stat-title text-secondary" onClick={toggleVideo}>
                            {showVideo ? 'Hide Journey Recording' : 'Show Journey Recording'}
                        </button>
                        {showVideo && (
                            <div className="stat-figure text-secondary pt-2">
                                <video width="640" height="480" controls>
                                    <source src={reportData.videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        )}
                    </div>
                </div>

                {console.log('reportData.testsData:', reportData.testsData)}
                {reportData.testsData.map((test, index) => (
                    <div key={index} className="collapse collapse-arrow bg-[#2b2b2b] px-12 mt-2">
                        <input type="radio" name="my-accordion-2" defaultChecked />
                        <div className="collapse-title text-xl font-medium pt-4">
                            <div className="flex justify-between item-center">
                                <p className="text-2xl mb-2 pt-4">{test.testName}</p>
                                <p className="text-end text-sm">Test ID: {test.testRunId}</p>
                            </div>
                            <div className="flex align-center">
                                <div className="stats stats-vertical lg:stats-horizontal shadow w-full bg-[#2f2f2f] border border-black">
                                    <div className="stat">
                                        <div className="stat-title text-sm text-[#60A5FA]">Status</div>
                                        <div className="stat-value text-secondary">{test.status}</div>
                                    </div>
                                    <div className="stat">
                                        <div className="stat-title text-sm text-[#60A5FA]">Duration</div>
                                        <div className="stat-value text-secondary">{test.durationMiliseconds / 1000} seconds</div>
                                    </div>
                                    <div className="stat">
                                        <div className="stat-title text-sm text-[#60A5FA]">Steps</div>
                                        <div className="stat-value text-secondary">{test.stepCount}</div>
                                    </div>
                                    <div className="stat mx-auto align-center">
                                        <div className="stat-title text-sm text-[#60A5FA]">Progress</div>
                                        <progress className="progress progress-warning w-56" value={test.progress} max="100"></progress>
                                        <span className="text-xs text-secondary">{test.progress}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="collapse-content ">
                            {test.stepsData
                                .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
                                .map((step, stepIndex) => (
                                    <details
                                    key={stepIndex}
                                    className={`${step.status === 'FAILED' ? 'FAILED border-2 border-red-500 rounded' : ''
                                      } bg-[#2d2d2d] rounded-md mb-4`} // Added mb-4 for margin-bottom
                                  >
                                    <summary className="step-summary">
                                      <span className="text-[#60A5FA] text-xs mb-4">Step {step.sequenceNumber}:</span>
                                      <p className="pl-4 text-lg text-secondary mb-4">
                                        {step.isPasswordInput ? 'Input the password Field with ********' : step.stepName}
                                      </p>
                                    </summary>
                                    <div className="step-detail text-base">
                                      <div className="flex flex-row justify-between">
                                        <p className="text-[#60A5FA]">
                                          Status: <span className={`badge ${step.status === 'PASSED' ? 'bg-green-500' : 'bg-red-500'}`}>{step.status}</span>
                                        </p>
                                        <p className="text-[#60A5FA]">Duration: {step.durationMiliseconds / 1000} seconds</p>
                                      </div>
                                      {step.screenshotUrl && (
                                        <div className="mt-2">
                                          <img
                                            src={step.screenshotUrl}
                                            alt={`Screenshot of step ${step.sequenceNumber}`}
                                            className="border border-gray-700 rounded"
                                            width="640"
                                            height="480"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </details>
                                    
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default JourneyRunReport;