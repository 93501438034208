import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaGithub } from "react-icons/fa";
import { IoLogoGoogle } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  emailPasswordSignUp,
  getAuthorisationURLWithQueryParamsAndSetState,
} from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { appLogin, sdkHome, sdkLogin, sdkVerifyEmail } from "../../../routes/routes";
import { logo_square_without_name } from "../../../assets";
import PasswordInput from "../components/PasswordInput";

function Signup({logFrom}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  async function signUpGoogle() {
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId: "google",
        frontendRedirectURI: `${process.env.REACT_APP_WEBSITE_DOMAIN}/app/auth/callback/thirdparty?tid=google`,
      });
      console.log(authUrl);
      window.location.assign(authUrl);
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        toast.error(err.message);
      } else if (err.status === 409) {
        toast.error("Email already exists!");
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  async function signUpGithub() {
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId: "github",
        frontendRedirectURI: `${process.env.REACT_APP_WEBSITE_DOMAIN}/app/auth/callback/thirdparty?tid=github`,
      });
      window.location.assign(authUrl);
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  const handleSignUp = async (data) => {
    setLoading(true);
    try {
      let response = await emailPasswordSignUp({
        formFields: [
          {
            id: "email",
            value: data.email,
          },
          {
            id: "password",
            value: data.password,
          },
          {
            id: "organisation_name",
            value: data.company_name,
          },
          {
            id: "name",
            value: `${data.first_name} ${data.last_name}`,
          },
          {
            id: "organisation_id",
            value: "None"
          },
          {
            id: "client",
            value: "sdk"
          }
        ],
      });
      if (response.status === "FIELD_ERROR") {
        setLoading(false);
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            toast.error(formField.error);
          } else if (formField.id === "password") {
            toast.error(formField.error);
          }
        });
      } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
        setLoading(false);
      } else {
        setLoading(false);
        navigate(logFrom?'/verify-email':sdkVerifyEmail);
      }
    } catch (err) {
      setLoading(false);
      if (err.isSuperTokensGeneralError === true) {
        toast.error(err.message);
      } else if (err.status === 409) {
        toast.error("Email already exists!");
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  };

  return (
    <div className="relative h-screen w-screen flex items-center justify-center bg-primary-color-dark overflow-hidden">
      <div
        className="flex items-center justify-center w-[200px] h-[200px] sm:w-[500px] sm:h-[500px] md:w-[650px] md:h-[650px] rounded-full"
        style={{
          background:
            "radial-gradient(circle at 50% 50%, #997529 0%, #985F2A 0%, #A1792D 0%, #985F2A 26%, #966125 41%, #825520 54%, #684323 71%, #2D2A2A 82%, #2D2A2A 100%)",
          boxShadow: "4px 6px 95px 29px #8f681b inset",
          animation: "colorChange1 5s infinite alternate",
        }}
      />

      <div className="absolute flex flex-row justify-end h-full w-screen">
        <div className="h-full w-full sm:w-1/2 backdrop-blur-[45px]">
          <div className="relative flex flex-row items-center justify-center h-full w-full overflow-y-scroll scrollable-content">
            <div className="flex flex-col items-center justify-center">
              <div className="flex justify-center flex-grow">
                <Link to={sdkHome}>
                  <img
                    src={logo_square_without_name}
                    alt="logo"
                    className="object-cover h-20 w-20"
                  />
                </Link>
              </div>
              <h1 className="text-white  text-3xl md:text-4xl mt-5 md:mt-8 font-primary  font-bold ">
                Sign up
              </h1>
              <p className="text-white text-base md:text-lg font-primary mt-4">
                Welcome to
                <span className="border ml-2 px-2 py-[2px] rounded-xl bg-secondary-color font-bold heading shadow-top-left-bottom-right">
                  Autoflow
                </span>
              </p>

              <form action="" onSubmit={handleSubmit(handleSignUp)}>
                <div className="flex flex-col md:flex-row md:items-center justify-center md:gap-4 mt-6">
                  <div className="flex flex-col justify-center -mb-2">
                    <label className="form-control w- max-w-xs">
                      <div className="label -mb-1">
                        <span className="label-text font-secondary text-white/70">
                          First Name
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter your First Name"
                        {...register("first_name", {
                          required: "*First Name is required",
                        })}
                        className={`input input-sm md:input-md bg-transparent border-white/70 outline-none focus:border-white/70 text-white font-secondary w-[350px] md:w-[250px] ${
                          errors?.first_name &&
                          "border-red-500 focus:border-red-500"
                        }`}
                      />
                      <div className="label">
                        <span className="label-text-alt">
                          {errors.first_name && (
                            <p className="text-red-500">
                              {errors.first_name.message}
                            </p>
                          )}
                        </span>
                      </div>
                    </label>
                  </div>
                  <div className="flex flex-col justify-center -mb-2">
                    <label className="form-control w-full max-w-xs">
                      <div className="label -mb-1">
                        <span className="label-text font-secondary text-white/70">
                          Last Name
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter your Last Name"
                        {...register("last_name", {
                          required: "*Last Name is required",
                        })}
                        className={`input input-sm md:input-md bg-transparent border-white/70 outline-none focus:border-white/70 text-white font-secondary w-[350px] md:w-[250px] ${
                          errors?.last_name &&
                          "border-red-500 focus:border-red-500"
                        }`}
                      />
                      <div className="label">
                        <span className="label-text-alt">
                          {errors.last_name && (
                            <p className="text-red-500">
                              {errors.last_name.message}
                            </p>
                          )}
                        </span>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="flex flex-col justify-center -mb-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label -mb-1">
                      <span className="label-text font-secondary text-white/70">
                        Work Email
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="Enter your email"
                      {...register("email", {
                        required: "*Work Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      className={`input input-sm md:input-md bg-transparent border-white/70 outline-none focus:border-white/70 text-white font-secondary w-[350px] md:w-[515px] ${
                        errors?.email && "border-red-500 focus:border-red-500"
                      }`}
                    />
                    <div className="label">
                      <span className="label-text-alt">
                        {errors.email && (
                          <p className="text-red-500">{errors.email.message}</p>
                        )}
                      </span>
                    </div>
                  </label>
                </div>

                <div className="flex flex-col justify-center -mb-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label -mb-1">
                      <span className="label-text font-secondary text-white/70">
                        Company Name
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="Enter your Company Name"
                      {...register("company_name", {
                        required: "*Comapny Name is required",
                      })}
                      className={`input input-sm md:input-md bg-transparent border-white/70 outline-none focus:border-white/70 text-white font-secondary w-[350px] md:w-[515px] ${
                        errors?.company_name &&
                        "border-red-500 focus:border-red-500"
                      }`}
                    />
                    <div className="label">
                      <span className="label-text-alt">
                        {errors.company_name && (
                          <p className="text-red-500">
                            {errors.company_name.message}
                          </p>
                        )}
                      </span>
                    </div>
                  </label>
                </div>

                <PasswordInput
                  inputWidth="w-[350px] md:w-[515px]"
                  register={register("password", {
                    required: "*Password is required",
                    minLength: {
                      value: 5,
                      message: "Password must be at least 5 characters long",
                    },
                  })}
                  errors={errors?.password}
                />

                <div className="mt-6">
                  <button
                    className={`btn btn-sm md:btn-md bg-primary-color border-none outline-none hover:bg-primary-color/85 w-[350px] md:w-[515px] font-secondary text-base ${
                      loading && "btn-disabled"
                    }`}
                  >
                    {loading ? (
                      <span className="loading loading-bars loading-sm md:loading-lg text-warning"></span>
                    ) : (
                      "Sign up"
                    )}
                  </button>
                </div>
              </form>

              <div className="flex flex-row items-center justify-center w-[300px] md:w-[500px] mx-auto my-6">
                <div className="border-t border-white w-1/4"></div>
                <div className="text-white font-primary font-bold mx-4 text-xs">
                  or sign-up with
                </div>
                <div className="border-t border-white w-1/4"></div>
              </div>

              <div className="flex flex-row gap-2">
                <div>
                  <button
                    className="btn btn-sm md:btn-md bg-black hover:bg-gray-900 border-gray-600 hover:border-gray-600 outline-none  w-[160px] md:w-[250px] font-secondary text-sm md:text-base text-white"
                    onClick={signUpGoogle}
                  >
                    <IoLogoGoogle />
                    Google
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm md:btn-md bg-black hover:bg-gray-900 border-gray-600 hover:border-gray-600 outline-none  w-[160px] md:w-[250px] font-secondary text-sm md:text-base text-white"
                    onClick={signUpGithub}
                  >
                    <FaGithub />
                    Github
                  </button>
                </div>
              </div>
              <div className="text-center mt-8 md:mt-10">
                <p className="text-white/55 font-secondary ">
                  Already have an account?{" "}
                  <Link
                    to={logFrom?appLogin:sdkLogin}
                    className="text-blue-300 font-secondary font-bold hover:text-blue-200"
                  >
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
